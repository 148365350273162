/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      logo: file(relativePath: { eq: "kxp-logo-dark-bg.png" }) {
        childImageSharp {
          fixed (height: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            linkedin
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >

      <p>
        Um blog escrito pelo <strong>{author.name}</strong>{author.summary}
        {` `}
        Dica: Você deveria nos seguir no {` `}
        <a href={`https://linkedin.com/company/${social.linkedin}`}>
           Linkedin
        </a> também.
      </p>
    </div>
  )
}

export default Bio
