import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"


const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  const data = useStaticQuery(graphql`
    query logoQuery {
      

      file(relativePath: { eq: "kxp-squared-logo-light-bg.png" }) {
        childImageSharp {
          fixed (height: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }`);

  header = (
    <div style={{
      verticalAlign: `middle`,
      align: `center`,
    }}>
      <span
        style={{
          ...scale(1.2),
          // marginBottom: rhythm(1.5),
          marginTop: 5
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </span>
    </div>
  )


  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer style={{ marginTop: rhythm(1.5) }}>
        © {new Date().getFullYear()}, Built by
        {` `}
        <a href="https://kxp.consulting">KXP</a>
      </footer>
    </div>
  )
}

export default Layout
